
.stock-dashboard {
    height: calc(100% - 92px);
    padding: 10px;
    overflow: hidden;
}

//Search bar
.stock-search-bar {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    .MuiAutocomplete-root {
        width: 100%;

        .MuiAutocomplete-inputRoot {
            border-radius: 4px;
        }
    }

    .search-icon {
        color: $primary-color;
    }

        .search-bar {
            flex-grow: 1;
        }  

        .MuiButton-root {
            height: 56px;
        }
}


.MuiPaper-root {
    height: calc(100% - 72px)
}

//Stoke Table
.MuiTableContainer-root {
    width: 100%;
    height: calc(100vh - 210px);
    max-height: calc(100vh - 210px); 
}
// .stock-table {
    .MuiTable-root {
        width: 100%; 
        border-collapse: separate;
        border-spacing: 0 4px;
        border-radius: 8px;
        // overflow: hidden;

        th,
        td {
            border: none;
            padding: 12px;
            // text-align: center;
        }

        th {
            background-color: #f5f5f5;
        }

        tbody tr:nth-child(even) {
            background-color: #f9f9f9;
        }

        tbody tr:hover {
            background-color: #f0f0f0;
        }

        tbody tr:last-child {
            td {
                border-bottom: none;
            }
        }
    }
// }

.no-data-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}