.layout {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #264653;
    color: white;

    .header-left {
        display: flex;
        align-items: center;

        .header-avatar {
            margin-right: 0.5rem;
            background-color: #e76f51;
        }

        .header-username {
            font-weight: bold;
        }
    }

    .header-right {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .icon-white{
            color: white;
        }
    }
}