.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .login-avatar {
        margin: 1rem;
        background-color: $primary-color
    }

    h1 {
        margin-bottom: 1rem;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .MuiFormControl-root {
            margin-bottom: 1rem;
        }

        .MuiButton-root {
            padding: 10px 16px;
            width: fit-content;
            border-radius: 20px; 
            margin-top: 1rem;
            background-color: $primary-color; 
            color: white; 

            &:hover {
                background-color: $primary-hover-color;
            }
        }
    }
}

.button-content{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
}