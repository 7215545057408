html,
body,
#root {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', Arial, sans-serif;
}

/* Add your other global styles here */

@import './colorPalette.scss';
@import './layoutProtected.scss';
@import './login.scss';
@import './stokeDashboard.scss';


.loader {
    position: absolute;
    height: 100%;
    width: 100%; 
   display: flex;
   align-items: center;
   justify-content: center;
    z-index: 10;
}

.Toastify__toast-container {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    >div {
        border-radius: 4px;
        width: fit-content;
        text-align: center;
    }
}

.toast-background {
    background-color: $primary-color;
    color: #fff;
}

.toast-body {
    font-weight: 550;
}

.Toastify__close-button--light {
    color: #fff;
}